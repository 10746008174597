/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { labelStyling } from 'orbiter-web-components/src/components/input/Input';

const Component = ({
    value,
    onChange,
    label,
}: {
    value: string,
    onChange: (v: string) => void,
    label: string,
}) => {
    return <div css={css`max-width: 500px; margin-left: auto; margin-right: auto;`}>
        <p css={labelStyling}>{label}</p>
        <textarea value={value} onChange={e => onChange(e.target.value)} css={css`
            display: inline-block;
            font-weight: 400;
            text-align: left;
            vertical-align: middle;
            border: 1px solid transparent;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 0.25rem;
            -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
            transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
            background-color: white;
            border-color: transparent;
            color: #282828;
            width: 100%;
            margin-bottom: 10px;
        `} />
    </div>
}

export default Component;