import { ReservationInformation } from "hotec-planner-shared/api/data";
import CustomButton from "hotec-planner-shared/components/CustomButton";
import View from "hotec-planner-shared/components/View";
import Spacer from "orbiter-web-components/src/components/spacer/Spacer";
import React, { useState } from "react";
import { titleCss } from "../pages/Home";
import { css } from "@emotion/core";
import CustomRadio from "hotec-planner-shared/components/CustomRadio";
import CustomInput from "hotec-planner-shared/components/CustomInput";
import CustomTextfield from "../CustomTextfield";
import { isValidEmail } from "orbiter-core/src/basic";

const Component = ({
    info,
    setInfo,
    onNext,
} : {
    info: ReservationInformation,
    setInfo: (info: ReservationInformation) => void,
    onNext: () => void,
}) => {

    const [addressesMatch, setAddressesMatch] = useState(true);

    return (
        <View>
            <div css={titleCss}>
                <h1>Enkele details.</h1>
                <p>We hebben nog wat meer informatie van u nodig om uw reservatie te bevestigen.</p>

                <Spacer height="50px" />

                <CustomInput label="Naam" onChange={(e) => {
                    const cl = info.clone();
                    cl.name = e.target.value;
                    setInfo(cl);
                }} value={info.name ?? ""} />
                <CustomInput label="Telefoonnummer" type="tel" onChange={(e) => {
                    const cl = info.clone();
                    cl.telephone = e.target.value;
                    setInfo(cl);
                }} value={info.telephone ?? ""} />
                <CustomInput label="E-mailadres" type="email" onChange={(e) => {
                    const cl = info.clone();
                    cl.email = e.target.value;
                    setInfo(cl);
                }} value={info.email ?? ""} />
                
                <Spacer height="50px" />

                <CustomTextfield label="Huidig adres" value={info.currentAddress} onChange={v => {
                    const cl = info.clone();
                    cl.currentAddress = v;
                    if(addressesMatch){
                        cl.worksiteAddress = v;
                    }
                    setInfo(cl);
                }}/>

                <CustomRadio name="address" label="Mijn werfadres is hetzelfde als mijn huidig adres" labelStyle={t => css`padding: 0;`} onChange={(e) => {
                    const cl = info.clone();
                    setAddressesMatch(true);
                    cl.worksiteAddress = cl.currentAddress;
                    setInfo(cl);
                }} checked={addressesMatch} />
                <CustomRadio name="address" label="Mijn werfadres verschilt van mijn huidig adres" labelStyle={t => css`padding: 0;`} onChange={(e) => {
                    const cl = info.clone();
                    setAddressesMatch(false);
                    cl.worksiteAddress = "";
                    setInfo(cl);
                }} checked={!addressesMatch} />

                {!addressesMatch && <CustomTextfield label="Werfadres" value={info.worksiteAddress} onChange={v => {
                    const cl = info.clone();
                    cl.worksiteAddress = v;
                    setInfo(cl);
                }}/>}

                <Spacer height="50px" />
                <CustomButton onClick={() => {
                    if(!info.name.trim()){
                        alert("Vergeet je naam niet in te vullen.");
                    }else if(!info.telephone.trim()){
                        alert("Vergeet je telefoonnummer niet in te vullen.");
                    }else if(!info.email.trim() || !isValidEmail(info.email)){
                        alert("Vergeet je e-mailadres niet in te vullen.");
                    }else if(!info.currentAddress.trim()){
                        alert("Vergeet je huidig adres niet in te vullen.");
                    }else if(!info.worksiteAddress.trim()){
                        alert("Vergeet je werfadres niet in te vullen.");
                    }else{
                        onNext();
                    }
                }}>Verder</CustomButton>
            </div>
        </View>
    )
}

export default Component;