import Home from './pages/Home';
// import C from './C';

const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home',
        exact: true,
    },
];
  
export default routes as any;