import { ReservationInformation } from "hotec-planner-shared/api/data";
import CustomButton from "hotec-planner-shared/components/CustomButton";
import View from "hotec-planner-shared/components/View";
import Spacer from "orbiter-web-components/src/components/spacer/Spacer";
import React from "react";
import { titleCss } from "../pages/Home";
import { css } from "@emotion/core";
import CustomRadio from "hotec-planner-shared/components/CustomRadio";
import CustomInput from "hotec-planner-shared/components/CustomInput";
import CustomTextfield from "../CustomTextfield";
import { isValidEmail } from "orbiter-core/src/basic";

const Component = ({
    info,
    setInfo,
    onNext,
} : {
    info: ReservationInformation,
    setInfo: (info: ReservationInformation) => void,
    onNext: () => void,
}) => {

    return (
        <View>
            <div css={titleCss}>
                <h1>Enkele details.</h1>
                <p>We hebben nog wat meer informatie van u nodig om uw reservatie te bevestigen.</p>

                <Spacer height="50px" />

                <CustomInput label="Naam" onChange={(e) => {
                    const cl = info.clone();
                    cl.name = e.target.value;
                    setInfo(cl);
                }} value={info.name ?? ""} />
                <CustomInput label="Telefoonnummer" type="tel" onChange={(e) => {
                    const cl = info.clone();
                    cl.telephone = e.target.value;
                    setInfo(cl);
                }} value={info.telephone ?? ""} />
                <CustomInput label="E-mailadres" type="email" onChange={(e) => {
                    const cl = info.clone();
                    cl.email = e.target.value;
                    setInfo(cl);
                }} value={info.email ?? ""} />
                
                <Spacer height="50px" />

                <CustomTextfield label="Huidig adres" value={info.currentAddress} onChange={v => {
                    const cl = info.clone();
                    cl.currentAddress = v;
                    setInfo(cl);
                }}/>
                <CustomTextfield label="Werfadres" value={info.worksiteAddress} onChange={v => {
                    const cl = info.clone();
                    cl.worksiteAddress = v;
                    setInfo(cl);
                }}/>
                
                <Spacer height="50px" />

                <CustomInput label="Projectnaam" onChange={(e) => {
                    const cl = info.clone();
                    cl.projectName = e.target.value;
                    setInfo(cl);
                }} value={info.projectName ?? ""} />
                <CustomInput label="Nummer appartement of woning" onChange={(e) => {
                    const cl = info.clone();
                    cl.appartmentNumber = e.target.value;
                    setInfo(cl);
                }} value={info.appartmentNumber ?? ""} />

                <CustomInput label="Projectcode" onChange={(e) => {
                    const cl = info.clone();
                    cl.projectCode = e.target.value;
                    setInfo(cl);
                }} value={info.projectCode ?? ""} />

                <Spacer height="50px" />
                <CustomButton onClick={() => {
                    if(!info.name.trim()){
                        alert("Vergeet je naam niet in te vullen.");
                    }else if(!info.telephone.trim()){
                        alert("Vergeet je telefoonnummer niet in te vullen.");
                    }else if(!info.email.trim() || !isValidEmail(info.email)){
                        alert("Vergeet je e-mailadres niet in te vullen.");
                    }else if(!info.currentAddress.trim()){
                        alert("Vergeet je huidig adres niet in te vullen.");
                    }else if(!info.worksiteAddress.trim()){
                        alert("Vergeet je werfadres niet in te vullen.");
                    }else if(!info.appartmentNumber.trim()){
                        alert("Vergeet de referentie van het lot of het appartementsnummer niet in te vullen.");
                    }else if(!info.projectName.trim()){
                        alert("Vergeet de projectnaam niet in te vullen.");
                    }else{
                        onNext();
                    }
                }}>Verder</CustomButton>
            </div>
        </View>
    )
}

export default Component;