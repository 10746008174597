import { ReservationInformation } from "hotec-planner-shared/api/data";
import CustomButton from "hotec-planner-shared/components/CustomButton";
import View from "hotec-planner-shared/components/View";
import Spacer from "orbiter-web-components/src/components/spacer/Spacer";
import React from "react";
import { titleCss } from "../pages/Home";
import { css } from "@emotion/core";
import CustomRadio from "hotec-planner-shared/components/CustomRadio";

const Component = ({
    info,
    setInfo,
    onNext,
} : {
    info: ReservationInformation,
    setInfo: (info: ReservationInformation) => void,
    onNext: () => void,
}) => {
    return (
        <View>
            <Spacer height="20vh" />
            <div css={titleCss}>
                <h1>Het project.</h1>
                <p>Maak een keuze. Komt u langs om een trap en/of deuren te kiezen als:</p>
                <CustomButton buttonStyle={_ => css`max-width: 450px;`} onClick={() => {
                    const cl = info.clone();
                    cl.type = "eindklant-sleutel-op-deur";
                    setInfo(cl);
                    onNext();
                }}>eindklant van een sleutel op de deur woning of appartement</CustomButton>
                <br/><CustomButton buttonStyle={_=>css`max-width: 400px;`} onClick={() => {
                    const cl = info.clone();
                    cl.type = "particulier-eigen-beheer";
                    setInfo(cl);
                    onNext();
                }}>particulier die bouwt in eigen beheer</CustomButton>
                <br/><CustomButton buttonStyle={_=>css`max-width: 400px;`} onClick={() => {
                    const cl = info.clone();
                    cl.type = "aannemer";
                    setInfo(cl);
                    onNext();
                }}>aannemer</CustomButton>
                {/* <CustomRadio name="project" label="eindklant van een sleutel op de deur woning of appartement" labelStyle={t => css`padding: 0;`} onChange={() => {
                    const cl = info.clone();
                    cl.type = "eindklant-sleutel-op-deur";
                    setInfo(cl);
                    onNext();
                }} checked={info.type === "eindklant-sleutel-op-deur"} />
                <CustomRadio name="project" label="particulier die bouwt in eigen beheer" labelStyle={t => css`padding: 0;`} onChange={() => {
                    const cl = info.clone();
                    cl.type = "particulier-eigen-beheer";
                    setInfo(cl);
                    onNext();
                }} checked={info.type === "particulier-eigen-beheer"} />
                <CustomRadio name="project" label="aannemer" labelStyle={t => css`padding: 0;`} onChange={() => {
                    const cl = info.clone();
                    cl.type = "aannemer";
                    setInfo(cl);
                    onNext();
                }} checked={info.type === "aannemer"} /> */}

            </div>
        </View>
    )
}

export default Component;