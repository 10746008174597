/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import Page from 'orbiter-web-components/src/components/page/Page';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import Footer from 'orbiter-web-components/src/components/footer/Footer';
import { ThemeProvider } from 'emotion-theming';
import { ProjectTheme } from '../Theme';
import View from 'hotec-planner-shared/components/View';
import CustomButton from 'hotec-planner-shared/components/CustomButton';
import Spacer from 'orbiter-web-components/src/components/spacer/Spacer';
import DayBrowser from 'hotec-planner-shared/components/DayBrowser';
import { isValidEmail } from 'orbiter-core/src/basic';
import TimeSlotIDC from 'orbiter-core-planner/lib/TimeSlotIDC';
import { momentToMonth } from 'orbiter-core-planner/lib/helper';
import moment from 'moment';
import { parseLoadedSlots, ReservationInformation } from 'hotec-planner-shared/api/data';
import ReservationAPI from 'orbiter-core-planner/lib/apicontroller/ReservationAPI';
import Reservation from 'orbiter-core-planner/lib/Reservation';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import { LoadedSlotsArray } from 'hotec-planner-shared/api/data';
import TypeSelection from '../views/TypeSelection';
import InfoTurnkeyCustomer from '../views/InfoTurnkeyCustomer';
import InfoDiyCustomer from '../views/InfoDiyCustomer';
import InfoContractor from '../views/InfoContractor';

export const defaultStyling = (theme: Theme) => {
    return css`
        color: ${applyTheme(theme, (t: Theme) => t.colors.primaryText)};
        line-height: ${applyTheme(theme, (t: Theme) => t.font.lineHeight.default)};
        font-family: ${applyTheme(theme, (t: Theme) => t.font.fontFamily.default)};
        font-size: ${applyTheme(theme, (t: Theme) => t.font.fontSize.default)};
        h1{
            font-weight: bold;
        }
    `;
};

export const fullHeightStyling = () => {
    return css`
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    `;
};

export const contentContainerStyling = (theme: Theme) => {
    return css`
        text-align: right;
        margin-right: 20%;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            text-align: left;
            margin-right: auto;
            padding-right: 40px !important;
            padding-left: 40px !important;
        }
    `;
};

export const backgroundStyling = (theme: Theme) => {
    return css`
        margin-right: 40%;
        box-shadow: inset -200px 0 100px #fff;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-right: 0;
            box-shadow: none;
        }
    `;
};

export const titleCss = css`
    padding: 5px;
    max-width: 1000px; 
    margin-left: auto; 
    margin-right: auto;
`;

class Home extends React.Component<any, {
    pageNr: number,
    done: boolean,
    loading: boolean,

    slots: LoadedSlotsArray,

    selectedSlot: null | TimeSlotIDC,
    info: ReservationInformation,
}> {

    constructor(a: any) {
        super(a);
        this.state = {
            pageNr: 0,
            done: false,
            loading: false,

            slots: parseLoadedSlots(this.props.slots),

            selectedSlot: null,
            info: ReservationInformation.create(),
        };
    }

    public render() {
        return (
            <ThemeProvider theme={ProjectTheme}>
                <Page
                    footer={<Footer />}
                    pageStyling={defaultStyling}>
                    {this.renderPage(this.state.pageNr)}
                </Page>
            </ThemeProvider>

        );
    }

    private submit(){
        const start = (this.state.selectedSlot as TimeSlotIDC).getStart();
        const end = (this.state.selectedSlot as TimeSlotIDC).getEnd();
        const availableTimeslotID = (this.state.selectedSlot as TimeSlotIDC).getId();

        this.setState({ loading: true, pageNr: 4 });

        ReservationAPI.create(new Reservation(start, end, availableTimeslotID, this.state.info, false), false)
            .then((res) => {
                this.setState({ done: true, pageNr: 5, loading: false, });
            })
            .catch((reason) => {
                this.setState({ loading: false, pageNr: 2 });
                alert("Er ging wat mis, probeer het later opnieuw. Sorry voor het ongemak.");
                console.error(reason);
            });
    }

    private renderPage(nr: number) {
        // return this.renderPageCovid();
        switch (nr) {
            case 0: {
                return this.renderPageWelcome();
            }
            case 1: {
                return <TypeSelection info={this.state.info} setInfo={info => this.setState({ info })} onNext={() => this.setState({ pageNr: 2 })} />
                //    return this.renderPageData();
            }
            case 2: {
                return this.renderPageSlots();
            }
            case 3: {
                if(this.state.info.type === "eindklant-sleutel-op-deur"){
                    return <InfoTurnkeyCustomer info={this.state.info} setInfo={info => this.setState({ info })} onNext={this.submit.bind(this)} />
                }else if(this.state.info.type === "particulier-eigen-beheer"){
                    return <InfoDiyCustomer info={this.state.info} setInfo={info => this.setState({ info })} onNext={this.submit.bind(this)} />
                }else if(this.state.info.type === "aannemer"){
                    return <InfoContractor info={this.state.info} setInfo={info => this.setState({ info })} onNext={this.submit.bind(this)} />
                }
                return <p>Er liep iets mis.</p>
            }
            case 4: {
                return this.renderLoadingPage();
            }
            case 5: {
                return this.renderPageConfirmation();
            }
            default: {
                throw Error();
            }
        }
    }

    private renderPageCovid() {
        return (
            <View>
                <Spacer height="20vh" />
                <div css={titleCss}>
                    <h1>Reservatie.</h1>
                    <p>Onze toonzaal is momenteel gesloten omwille van de geldende Coronamaatregelen. Het is helaas niet mogelijk om een reservatie te maken.</p>
                </div>
            </View>
        )
    }
    private renderPageWelcome() {
        return (
            <View>
                <Spacer height="20vh" />
                <div css={titleCss}>
                    <h1>Reservatie.</h1>
                    <p>Welkom op de Hotec reservatiepagina. Klik op 'plan bezoek' om een reservatie te maken.</p>
                </div>
                <Spacer height="100px" />
                <CustomButton onClick={() => this.setState({ pageNr: 1 })}>Plan bezoek</CustomButton>
            </View>
        )
    }

    private renderPageSlots() {
        const slots = this.state.slots.filter(s => s.slots.length > 0);

        return (
            <View>
                <div css={titleCss}>
                    <h1>Kies een moment.</h1>
                    <p>Kies een vrij moment uit waarop u onze toonzaal wenst te bezoeken.</p>
                </div>
                <Spacer height="50px" />
                {slots.length === 0 ? <p>Er zijn geen momenten beschikbaar.</p> : <DayBrowser
                    onSelect={(slot: TimeSlotIDC) => {
                        this.setState({ pageNr: 3, selectedSlot: slot });
                    }}
                    slots={slots}
                />}
            </View>
        )
    }

    private renderPageConfirmation() {
        const slot: moment.Moment = moment(this.state.selectedSlot.getStart());
        return (
            <View>
                <div css={titleCss}>
                    <h1>Bedankt.</h1>

                    <img src="/check.svg" css={css`width: 100%; max-width: 300px; padding: 20px;`} />

                    <p>Uw reservatie werd met success geregistreerd.</p>
                    <p>We ontvangen je graag op <b>{slot.format("D")} {momentToMonth(slot)} {slot.format("YYYY")}</b> om <b>{slot.format("H:mm")}</b>.</p>
                </div>

            </View>
        )
    }

    private renderLoadingPage() {
        return (
            <View>
                <div css={titleCss}>
                    <h1>Even geduld.</h1>
                    <p>Uw reservatie wordt gecontroleerd en opgeslagen.</p>

                    <div css={css`margin-top: 100px; margin-bottom: 100px;`}>
                        <Loader
                            type="Rings"
                            color="white"
                            height={100}
                            width={100}
                        />
                    </div>

                </div>

            </View>
        )
    }

}

export default Home;
